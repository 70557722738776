import styles from "./Balance.module.scss";

import React from "react";
import clsx from "clsx";
import Text from "../base/Text/Text";
import ShineTokenName from "../base/ShineTokenName/ShineTokenName";
import { formatLargeNumber } from "../../utils/strings";

import { TOKEN_NAME } from "../../constants";

const TokenBalance = ({
  variant = "h2",
  balance,
  className,
  description = `Your ${TOKEN_NAME} Points`,
  formatAmount = true,
  spentDrums = undefined,
}) => (
  <div
    className={clsx(
      styles.balanceContainer,
      spentDrums !== undefined && styles.balanceContainerWithSpentDrums,
      className,
    )}
  >
    <Text variant="textM" weight="bold" color="green">
      {description}
    </Text>
    <div className={styles.balance}>
      <Text variant={variant} weight="bold">
        {balance ? (formatAmount ? formatLargeNumber(balance) : balance) : 0}
      </Text>
      <ShineTokenName />
    </div>
    {spentDrums !== undefined && (
      <div className={styles.spentDrums}>
        <Text variant="textXM" weight="semiBold" color="green">
          DRUMs spent:
        </Text>{" "}
        <Text variant="textXM" weight="bold">
          {formatLargeNumber(spentDrums)}
        </Text>
      </div>
    )}
  </div>
);

export default TokenBalance;
