import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useContext,
} from "react";
import Text from "../../base/Text/Text";
import { useApp } from "../../../context/AppProvider";
import useApi from "../../../api/rest";
import { TOKEN_NAME } from "../../../constants";
import { useTelegram } from "../../../context/TelegramProvider";
import styles from "./Tasks.module.scss";
import { formatNumberForUi } from "../../../utils/strings";
import Task from "./components/Task";
import RegisterTask from "./components/RegisterTask";
import clsx from "clsx";
import Button from "../../base/Button/Button";
import { WatchAdModalContext } from "../../Modals/WatchAdModal/WatchAdModal";

const Tasks = () => {
  const storageKey = "seenPromoTasks";

  const { webApp } = useTelegram();
  const {
    userInfo = {},
    setUserInfo,
    setShowSuccessModal,
    setSuccessModalData,
  } = useApp();
  const { startTask, claimTask, checkTask } = useApi();
  const [tasks, setTasks] = useState({
    main: [],
    completed: [],
    promo: [],
  });

  const [startTaskLoading, setStartTaskLoading] = useState(null);
  const [claimTaskLoading, setClaimTaskLoading] = useState(false);
  const [checkTaskLoading, setCheckTaskLoading] = useState(null);
  const [message, setMessage] = useState({ show: false, text: "" });

  const { handleModalState: setWatchAdModal } = useContext(WatchAdModalContext);

  const [currentTab, setCurrentTab] = useState("main");
  const [hasNewPromoTasks, setHasNewPromoTasks] = useState(false);

  const showMessageHandler = useCallback((text) => {
    setMessage({ show: true, text });
    setTimeout(() => setMessage({ show: false, text: "" }), 3000);
  }, []);

  const updateNewPromoTasksStatus = useCallback(() => {
    const seenPromoTasks = JSON.parse(localStorage.getItem(storageKey) || "[]");
    const currentPromoTaskIds = tasks.promo.map((task) => task.taskId);
    const hasNewTasks = currentPromoTaskIds.some((taskId) => {
      const task = tasks.promo.find((t) => t.taskId === taskId);
      return !seenPromoTasks.includes(taskId) && !task?.taskData?.isViewed;
    });
    setHasNewPromoTasks(hasNewTasks);
  }, [tasks.promo]);

  const savePromoTaskToLocalStorage = useCallback(
    (taskId) => {
      const seenPromoTasks = JSON.parse(
        localStorage.getItem(storageKey) || "[]",
      );
      if (!seenPromoTasks.includes(taskId)) {
        seenPromoTasks.push(taskId);
        localStorage.setItem(storageKey, JSON.stringify(seenPromoTasks));
        updateNewPromoTasksStatus();
      }
    },
    [updateNewPromoTasksStatus],
  );

  const handleGoTask = useCallback(
    (data) => {
      const { taskDetails: details, type, inviteUrl, isPromo, taskId } = data;

      if (isPromo) {
        savePromoTaskToLocalStorage(taskId);
        updateNewPromoTasksStatus();
      }

      if (type === "referral" && webApp) {
        if (webApp.platform === "ios") {
          try {
            webApp.openTelegramLink(inviteUrl);
          } catch (error) {
            console.error("handleGoTask error: ", error, { inviteUrl });
            window.open(inviteUrl, "_blank");
          }
        } else {
          window.open(inviteUrl, "_blank");
        }
      }

      if (type === "social" && details?.action === "redirect" && webApp) {
        if (webApp.platform === "ios") {
          if (details.redirectUrl?.includes("t.me")) {
            try {
              webApp.openTelegramLink(details.redirectUrl);
            } catch (error) {
              console.error("handleGoTask error: ", error, {
                redirectUrl: details.redirectUrl,
              });
            }
          } else {
            webApp.openLink(details.redirectUrl);
          }
        } else {
          window.open(details.redirectUrl, "_blank");
        }
      }
    },
    [webApp, savePromoTaskToLocalStorage, updateNewPromoTasksStatus],
  );

  const handleStartTask = useCallback(
    (task) => {
      setStartTaskLoading(task.taskId);
      if (task.taskId === "watch_adsgram") {
        setWatchAdModal(true, task?.taskData);
        setStartTaskLoading(null);
        savePromoTaskToLocalStorage(task.taskId);
        updateNewPromoTasksStatus();

        return;
      }
      startTask(task.taskId)
        .then((data) => {
          if (data.status === "error") {
            const reason =
              data.error?.response?.data?.data?.reason ?? data.reason ?? "";
            const reasonMessage = reason ? `: ${reason}` : "";
            showMessageHandler("Failed to start task" + reasonMessage);
            return;
          }

          setTimeout(() => {
            const updatedTasks = userInfo?.tasks ?? {};
            updatedTasks[task.taskId] = data;

            setUserInfo({
              ...userInfo,
              tasks: updatedTasks,
            });
            setStartTaskLoading(null);

            if (data.taskDetails) {
              if (task.taskData.isPromo) {
                savePromoTaskToLocalStorage(task.taskId);
                updateNewPromoTasksStatus();
              }
              handleGoTask(task.taskData);
            }
          }, 1000);
        })
        .catch((error) => {
          console.error("startTask error: ", error);

          showMessageHandler(
            `An error occurred while starting the task` + error.message ?? "",
          );
        })
        .finally(() => {
          setStartTaskLoading(null);
        });
    },
    [
      startTask,
      setUserInfo,
      handleGoTask,
      userInfo,
      showMessageHandler,
      savePromoTaskToLocalStorage,
      updateNewPromoTasksStatus,
      setWatchAdModal,
    ],
  );

  const handleCheckTask = useCallback(
    (taskId) => {
      setCheckTaskLoading(taskId);
      checkTask(taskId)
        .then((data) => {
          if (data.status === "error") {
            const reason =
              data.error?.response?.data?.data?.reason ?? data.reason ?? "";
            const reasonMessage = reason ? `: ${reason}` : "";
            console.error("checkTask error: ", data);
            showMessageHandler(`Failed to check task` + reasonMessage);
            return;
          }

          setTimeout(() => {
            const updatedTasks = userInfo?.tasks ?? {};
            updatedTasks[taskId] = data;

            setUserInfo({
              ...userInfo,
              tasks: updatedTasks,
            });
          }, 1000);
        })
        .catch((error) => {
          console.error("checkTask error: ", error);

          showMessageHandler(
            `An error occurred while checking the task`,
            error.message ?? "",
          );
        })
        .finally(() => {
          setCheckTaskLoading(null);
        });
    },
    [checkTask, userInfo, showMessageHandler, setUserInfo],
  );

  const checkForNewPromoTasks = useCallback((promoTasks) => {
    const seenPromoTasks = JSON.parse(localStorage.getItem(storageKey) || "[]");

    const currentPromoTaskIds = promoTasks.map((task) => task.taskId);

    const hasNewTasks = currentPromoTaskIds.some(
      (taskId) => !seenPromoTasks.includes(taskId),
    );

    setHasNewPromoTasks(hasNewTasks);
  }, []);

  const handleClaim = useCallback(
    (taskId) => {
      setClaimTaskLoading(taskId);
      claimTask(taskId)
        .then((data) => {
          if (data.status === "error") {
            const reason =
              data.error?.response?.data?.data?.reason ?? data.reason ?? "";
            const reasonMessage = reason ? `: ${reason}` : "";
            showMessageHandler("Failed to claim task" + reasonMessage);
            return;
          }

          const updatedTasks = userInfo?.tasks ?? {};
          updatedTasks[taskId] = data.task;
          updatedTasks[taskId].isViewed = true;

          setUserInfo({
            ...userInfo,
            balance: data.balance,
            tasks: updatedTasks,
          });

          setShowSuccessModal(true);
          setSuccessModalData({
            reward: (
              <Text variant="h1" weight="semiBold">
                +{formatNumberForUi(data.claimedBalance)}{" "}
                <Text variant="h1" weight="semiBold" color="gradient">
                  {TOKEN_NAME}
                </Text>
              </Text>
            ),
            description: (
              <Text variant="textL">
                Stay bright, keep the fight,
                <br />
                In every challenge, find the light.
                <br />
                Earn more {TOKEN_NAME}!
              </Text>
            ),
          });
        })
        .catch((error) => {
          console.error("claimTask error: ", error);

          showMessageHandler(
            "An error occurred while claiming the task" + error.message ?? "",
          );
        })
        .finally(() => {
          setClaimTaskLoading(null);
        });
    },
    [
      claimTask,
      setUserInfo,
      setShowSuccessModal,
      setSuccessModalData,
      userInfo,
      showMessageHandler,
    ],
  );

  useEffect(() => {
    if (userInfo?.tasks) {
      const taskMainPre = [];
      const taskDonePre = [];
      const promoTasksPre = [];

      Object.entries(userInfo.tasks).forEach(([key, task]) => {
        const taskData = {
          ...(task.type === "referral" && { inviteUrl: userInfo.inviteUrl }),
          ...task,
          taskId: key,
        };

        if (task.state === "Claimed") {
          taskDonePre.push({ taskId: key, taskData });
        } else {
          if (task.isPromo) {
            promoTasksPre.push({ taskId: key, taskData });
          } else {
            taskMainPre.push({ taskId: key, taskData });
          }
        }
      });

      const sortedTasks = (tasks, sortArray) =>
        sortArray
          .reduce((acc, el) => {
            const task = tasks.find((t) => t.taskId === el);
            if (task) acc.push(task);
            return acc;
          }, [])
          .concat(tasks.filter((task) => !sortArray.includes(task.taskId)));

      const sortedPromo = sortedTasks(promoTasksPre, SORT_PROMO);
      setTasks({
        main: sortedTasks(taskMainPre, SORT),
        completed: sortedTasks(taskDonePre, SORT.concat(SORT_PROMO)),
        promo: sortedTasks(promoTasksPre, SORT_PROMO),
      });

      checkForNewPromoTasks(sortedPromo);
    }
  }, [userInfo, checkForNewPromoTasks]);

  const renderTask = useCallback(
    (task, index) => {
      const commonProps = {
        task,
        handleStartTask,
        startTaskLoading,
        handleGoTask,
        handleClaim,
        claimTaskLoading,
        handleCheckTask,
        checkTaskLoading,
      };

      if (task.taskId === "register_on_cex_io") {
        return (
          <RegisterTask
            key={task.taskId}
            {...commonProps}
            userData={{
              email: userInfo.email,
              sentEmail: userInfo?.sentEmail,
              sentEmailCreatedAtFormatted:
                userInfo?.sentEmailCreatedAtFormatted,
            }}
          />
        );
      }
      return <Task key={task.taskId} {...commonProps} />;
    },
    [
      handleStartTask,
      startTaskLoading,
      handleGoTask,
      handleClaim,
      claimTaskLoading,
      handleCheckTask,
      checkTaskLoading,
      userInfo,
    ],
  );

  const tasksToRender = useMemo(() => {
    switch (currentTab) {
      case "promo":
        return tasks.promo ?? [];
      case "completed":
        return tasks.completed;
      default:
        return tasks.main;
    }
  }, [currentTab, tasks]);

  const getPromoButtonClass = () => {
    if (currentTab === "promo") {
      return styles.toggleActive;
    }
    if (hasNewPromoTasks) {
      return styles.toggleNew;
    }

    return styles.toggleInactive;
  };

  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <Text tag="p" variant="h2" weight="bold">
          Achieve the task,
          <br />
          earn the tribal reward!
        </Text>
        <Text tag="p" variant="textXL" weight="bold" color="gradient">
          But keep in mind, only qualified actions
          <br />
          unlock {TOKEN_NAME} tokens! ✨
        </Text>
      </div>
      <div className={styles.taskNav}>
        <Button
          variant="filledAccent"
          onClick={() => setCurrentTab("main")}
          className={clsx(
            styles.navButton,
            // styles.toggleElement,
            currentTab === "main" ? styles.toggleActive : styles.toggleInactive,
          )}
        >
          <Text
            variant="textL"
            color={currentTab !== "main" ? "green" : undefined}
          >
            Main
          </Text>
        </Button>
        <Button
          variant="filledAccent"
          onClick={() => setCurrentTab("promo")}
          className={clsx(styles.navButton, getPromoButtonClass())}
        >
          <Text
            variant="textL"
            color={currentTab !== "promo" ? "green" : undefined}
          >
            Promo
          </Text>
          {hasNewPromoTasks && (
            <div className={styles.newLabel}>
              <Text variant="textS" weight="bold">
                New
              </Text>
            </div>
          )}
        </Button>
        <Button
          variant="filledAccent"
          size="sizeL"
          onClick={() => setCurrentTab("completed")}
          className={clsx(
            styles.navButton,
            currentTab === "completed"
              ? styles.toggleActive
              : styles.toggleInactive,
          )}
        >
          <Text
            variant="textL"
            color={currentTab !== "completed" ? "green" : undefined}
          >
            Finished
          </Text>
        </Button>
      </div>
      <ul className={styles.taskList}>{tasksToRender.map(renderTask)}</ul>
      {message.show && (
        <div className={styles.popupMessage}>
          <Text variant="textXM" weight="bold">
            {message.text}
          </Text>
        </div>
      )}
    </div>
  );
};

export default Tasks;

const SORT = [
  "watch_video_essential_crypto_safety_tips",
  "watch_learn_avoid_crypto_scams",
  "subscribe_telegram",
  "follow_on_x",
  "share_tweet_on_x",
  "share_tweet_on_x_2",
  "share_post_on_facebook_2",
  "join_discord",
  "join_reddit",
  "follow_on_youtube",
  "boost_telegram_1",
  "install_wigwam_extension",
  "share_post_on_facebook",
  "invite_1_friend",
  "invite_5_friends",
  "invite_10_friends",
  "invite_20_friends",
  "invite_50_friends",
  "invite_100_friends",
  "join_cex_io",
  "share_tweet_on_twitter",
  "join_linkedin",
  "join_facebook",
  "review_chrome_webstore",
];

const SORT_PROMO = [
  "join_y_crypto_superapp",
  "watch_adsgram",
  "join_promo",
  "test_promo",
];
