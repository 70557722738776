import axios from "axios";
import { useTelegram } from "../context/TelegramProvider";
import { useCallback } from "react";
import genToken from "../utils/accessToken";

const apiBaseUrl =
  window.location.hostname === "drum.wigwam.app"
    ? "https://drumapi.wigwam.app"
    : window.location.hostname === "drumbot-staging-webapp.pages.dev"
    ? "https://staging-drumapi.wigwam.app"
    : process.env.REACT_APP_API_HOST || "";

const restApiUrl = `${apiBaseUrl}/api`;

const RestApi = axios.create({
  baseURL: restApiUrl,
});

const useApi = () => {
  const { webApp, user } = useTelegram();

  const sendLog = (data) => {
    const client = axios.create({
      baseURL: apiBaseUrl,
    });

    return client
      .post("/log", {
        devAuthData: user?.id,
        authData: webApp?.initData,
        platform: webApp?.platform,
        data,
      })
      .then((result) => {
        if (result.status === 200) {
          return result.data;
        }
      })
      .catch((error) => {
        return {
          status: "error",
          error,
        };
      });
  };

  const getUserInfo = useCallback(() => {
    return RestApi.post("/getUserInfo", {
      devAuthData: user?.id,
      authData: webApp?.initData,
      platform: webApp?.platform,
      data: {},
    })
      .then((result) => {
        if (result.status === 200) {
          return result.data.data;
        }

        if (result.data.code === "USER_NOT_FOUND") {
          return {
            status: "error",
            code: "USER_NOT_FOUND",
          };
        }
      })
      .catch((error) => {
        return {
          status: "error",

          error,
        };
      });
  }, [user?.id, webApp?.initData, webApp?.platform]);

  const getGameInfo = useCallback(() => {
    return RestApi.get("/gameplay")
      .then((result) => {
        if (result.status === 200) {
          return result.data;
        }
      })
      .catch((error) => {
        return {
          status: "error",
          error,
        };
      });
  }, []);

  const setUserEmail = useCallback(
    (email) => {
      return RestApi.post("/setEmail", {
        devAuthData: user?.id,
        authData: webApp?.initData,
        data: { email },
      })
        .then((result) => {
          if (result.status === 200) {
            return result.data;
          }
        })
        .catch((error) => {
          return {
            status: "error",
            error,
          };
        });
    },
    [user?.id, webApp?.initData],
  );

  const claimTap = useCallback(
    (amount) => {
      return RestApi.post("/claimTap", {
        devAuthData: user?.id,
        authData: webApp?.initData,
        data: { amount },
      })
        .then((result) => {
          if (result.status === 200) {
            return result.data;
          }
        })
        .catch((error) => {
          return {
            status: "error",
            error,
          };
        });
    },
    [user?.id, webApp?.initData],
  );

  const claimTaps = useCallback(
    (taps, amount) => {
      return RestApi.post("/claimTaps", {
        devAuthData: user?.id,
        authData: webApp?.initData,
        data: { taps, amount },
      })
        .then((result) => {
          if (result.status === 200) {
            return result.data.data;
          }
        })
        .catch((error) => {
          return {
            status: "error",
            error,
          };
        });
    },
    [user?.id, webApp?.initData],
  );

  const upgradeDrum = useCallback(
    (drumId) => {
      const url = window.location.href;
      const userAgent = navigator.userAgent;

      const token = genToken(user?.id, webApp?.initData);

      return RestApi.post("/gameplay/upgradeDrum", {
        devAuthData: user?.id,
        authData: webApp?.initData,
        data: { drumId, url, userAgent, app: true, randomString: token },
      })
        .then((result) => {
          if (result.status === 200) {
            return result.data.toUpdate;
          }
        })
        .catch((error) => {
          return {
            status: "error",
            error,
          };
        });
    },
    [user?.id, webApp?.initData],
  );

  const changeDrum = useCallback(
    (drumId) => {
      return RestApi.post("/gameplay/setDrum", {
        devAuthData: user?.id,
        authData: webApp?.initData,
        data: { drumId },
      })
        .then((result) => {
          if (result.status === 200) {
            return result.data.toUpdate;
          }
        })
        .catch((error) => {
          return {
            status: "error",
            error,
          };
        });
    },
    [user?.id, webApp?.initData],
  );

  const startFarm = useCallback(() => {
    return RestApi.post("/gameplay/startFarm", {
      devAuthData: user?.id,
      authData: webApp?.initData,
      data: {},
    })
      .then((result) => {
        if (result.status === 200) {
          return result.data;
        }
      })
      .catch((error) => {
        return {
          status: "error",
          error,
        };
      });
  }, [user?.id, webApp?.initData]);

  const claimFarm = useCallback(() => {
    return RestApi.post("/gameplay/claimFarm", {
      devAuthData: user?.id,
      authData: webApp?.initData,
      data: {},
    })
      .then((result) => {
        if (result.status === 200) {
          return result.data;
        }
      })
      .catch((error) => {
        return {
          status: "error",
          error,
        };
      });
  }, [user?.id, webApp?.initData]);

  const upgradeGameLevel = useCallback(() => {
    return RestApi.post("/gameplay/upgradeGameLevel", {
      devAuthData: user?.id,
      authData: webApp?.initData,
      data: {},
    })
      .then((result) => {
        if (result.status === 200) {
          return result.data.toUpdate;
        }
      })
      .catch((error) => {
        return {
          status: "error",
          error,
        };
      });
  }, [user?.id, webApp?.initData]);

  const upgradeSkill = useCallback(
    (skillId) => {
      return RestApi.post("/gameplay/upgradeSkill", {
        devAuthData: user?.id,
        authData: webApp?.initData,
        data: { skillId },
      })
        .then((result) => {
          if (result.status === 200) {
            return result.data.toUpdate;
          }
        })
        .catch((error) => {
          return {
            status: "error",
            error,
          };
        });
    },
    [user?.id, webApp?.initData],
  );

  const getChildren = useCallback(() => {
    return RestApi.post("/getChildren", {
      devAuthData: user?.id,
      authData: webApp?.initData,
      data: {},
    })
      .then((result) => {
        if (result.status === 200) {
          return result.data;
        }
      })
      .catch((error) => {
        return {
          status: "error",
          error,
        };
      });
  }, [user?.id, webApp?.initData]);

  const claimFromChildren = useCallback(() => {
    return RestApi.post("/claimFromChildren", {
      devAuthData: user?.id,
      authData: webApp?.initData,
      data: {},
    })
      .then((result) => {
        if (result.status === 200) {
          return result.data;
        }
      })
      .catch((error) => {
        return {
          status: "error",
          error,
        };
      });
  }, [user?.id, webApp?.initData]);

  const startTask = useCallback(
    (taskId) => {
      return RestApi.post("/startTask", {
        devAuthData: user?.id,
        authData: webApp?.initData,
        data: { taskId },
      })
        .then((result) => {
          if (result.status === 200) {
            return result.data.data;
          }
        })
        .catch((error) => {
          return {
            status: "error",
            error,
          };
        });
    },
    [user?.id, webApp?.initData],
  );

  const claimTask = useCallback(
    (taskId) => {
      return RestApi.post("/claimTask", {
        devAuthData: user?.id,
        authData: webApp?.initData,
        data: { taskId },
      })
        .then((result) => {
          if (result.status === 200) {
            return result.data.data;
          }
        })
        .catch((error) => {
          return {
            status: "error",
            error,
          };
        });
    },
    [user?.id, webApp?.initData],
  );

  const checkTask = useCallback(
    (taskId, taskData) => {
      const reqData = {
        devAuthData: user?.id,
        authData: webApp?.initData,
        data: { taskId },
      };
      if (taskData) {
        reqData.data.taskData = taskData;
      }
      return RestApi.post("/checkTask", reqData)
        .then((result) => {
          if (result.status === 200) {
            return result.data.data;
          }
        })
        .catch((error) => {
          return {
            status: "error",
            reason:
              error?.response?.data?.data?.reason ||
              "Can't verify, try again in several minutes",
          };
        });
    },
    [user?.id, webApp?.initData],
  );

  const verifyEmail = useCallback(
    (email) => {
      return RestApi.post("/verifyEmail", {
        devAuthData: user?.id,
        authData: webApp?.initData,
        data: { email },
      })
        .then((result) => {
          if (result.status === 200) {
            return result.data;
          }
        })
        .catch((error) => {
          return {
            status: "error",
            reason:
              error.response?.data?.data?.reason ||
              "Something went wrong, try again in several minutes",
          };
        });
    },
    [user?.id, webApp?.initData],
  );

  const getPosts = useCallback(() => {
    return RestApi.post("/getPosts", {
      devAuthData: user?.id,
      authData: webApp?.initData,
      data: {},
    })
      .then((result) => {
        if (result.status === 200) {
          return result.data.data;
        }
      })
      .catch((error) => {
        return {
          status: "error",
          error,
        };
      });
  }, [user?.id, webApp?.initData]);

  const markUpdateChecked = useCallback(
    (updateId) => {
      return RestApi.post("/markUpdateAsViewed", {
        devAuthData: user?.id,
        authData: webApp?.initData,
        data: {
          updateId,
        },
      })
        .then((result) => {
          if (result.status === 200) {
            return result.data;
          }
        })
        .catch((error) => {
          return {
            status: "error",
            error,
          };
        });
    },
    [user?.id, webApp?.initData],
  );

  return {
    sendLog,
    getUserInfo,
    getGameInfo,
    setUserEmail,
    claimTap,
    claimTaps,
    upgradeGameLevel,
    upgradeSkill,
    upgradeDrum,
    claimFarm,
    startFarm,
    getChildren,
    claimFromChildren,
    startTask,
    claimTask,
    checkTask,
    verifyEmail,
    changeDrum,
    markUpdateChecked,
    getPosts,
  };
};

export default useApi;
